import React, { FC, useLayoutEffect, useState } from 'react'
import {
  OriginalImage,
  OriginalImagePreLoad,
  WorkType,
} from 'src/components/Image'
import { asSimpleModal, ModalProps } from 'src/components/Modal'
import styled, { css } from 'styled-components'
import { Spin } from '../Loading'

const disableScrollFn = (e: TouchEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
const closeDurationMs = 300
const OriginalImageComponent: FC<ModalProps<{ image: WorkType }>> = ({
  image,
  closeModal,
}) => {
  const [hasContainerOpened, setHasContainerOpened] = useState(false)
  const [hasImgLoaded, setHasImgLoaded] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const close = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsClosing(true)
    setTimeout(() => {
      closeModal()
    }, closeDurationMs)
  }
  useLayoutEffect(() => {
    addEventListener('touchmove', disableScrollFn, {
      passive: false,
    })
    return () => removeEventListener('touchmove', disableScrollFn)
  }, [])
  return (
    <OriginalImageContainer
      onClick={close}
      onAnimationEnd={() => setHasContainerOpened(true)}
      isClosing={isClosing}
    >
      {hasContainerOpened && (
        <OriginalImagePreLoad
          image={image}
          onLoad={() => setHasImgLoaded(true)}
        />
      )}
      <OriginalImageBoxContainer>
        {!isClosing && (
          <OriginalImageBox className={hasImgLoaded ? 'loaded' : ''}>
            <OriginalImageOverlay className={hasImgLoaded ? 'loaded' : ''} />
            <LoadingContainer>
              <LoadingArea>
                <Spin color="#eaeaea" size="xl" />
              </LoadingArea>
            </LoadingContainer>
            <OriginalImage image={image} />
          </OriginalImageBox>
        )}
      </OriginalImageBoxContainer>
    </OriginalImageContainer>
  )
}
export const OriginalImageModal = asSimpleModal(OriginalImageComponent)

const OriginalImageContainer = styled.div<{ isClosing: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);

  animation: openImageContainer 0.3s ease-in-out;
  @keyframes openImageContainer {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  ${({ isClosing }) =>
    isClosing &&
    css`
      animation: closeImageContainer 0.3s ease-in-out forwards;
      @keyframes closeImageContainer {
        100% {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    `}
`
const OriginalImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  &.loaded {
    background-color: rgba(0, 0, 0, 1);
    animation: openImageBox 1s ease-in-out;
    @keyframes openImageBox {
      0% {
        background-color: rgba(0, 0, 0, 0);
        filter: blur(10px);
      }
      50% {
        background-color: rgba(0, 0, 0, 1);
        /* filter: blur(0px); */
      }
      100% {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }
  z-index: -1;
`
const OriginalImageBoxContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const OriginalImageBox = styled.div`
  margin: 5vh;
  position: relative;
  img {
    max-width: 100vw;
    max-height: 90vh;
    object-fit: scale-down;
    opacity: 0;
  }
  &.loaded {
    img {
      opacity: 1;
      animation: openImage 1s ease-in-out, forwards;
      @keyframes openImage {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
`
const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
`
const LoadingArea = styled.div``
