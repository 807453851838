import * as React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { ModalProps } from './asModal'

type AsModalType = <T>(
  Component: React.FC<ModalProps<T>>
) => React.FC<ModalProps<T>>
export const asSimpleModal: AsModalType = (Component) => {
  return (props) => {
    const { closeModal } = props
    return (
      <StyledModal
        isOpen
        onRequestClose={() => closeModal()}
        portalClassName="react-modal-portal"
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldFocusAfterRender={false}
      >
        <ModalAreaOverlay onClick={closeModal}>
          <ModalArea onClick={(e) => e && e.stopPropagation()}>
            <Component {...props} closeModal={closeModal} />
          </ModalArea>
        </ModalAreaOverlay>
      </StyledModal>
    )
  }
}
const StyledModal = styled(Modal)`
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  :focus {
    outline: none;
  }
`
const ModalAreaOverlay = styled.div`
  width: 100%;
  height: 100%;
  :focus {
    outline: none;
  }
`

const ModalArea = styled.div`
  width: 100%;
`
