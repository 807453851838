import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { getWorks, StaticImageFluid, WorkType } from 'src/components/Image'
import { OriginalImageModal } from 'src/components/OriginalImageDisplay'
import { SEO } from 'src/components/seo'
import { Title } from 'src/components/Title'
import { GlobalStyles } from 'src/styles'
import styled from 'styled-components'

export const WorksLayout = () => (
  <Layout>
    <GlobalStyles bgColor="#eaeaea" />
    <Contents />
    <SEO />
  </Layout>
)
const Contents = () => {
  const [selectedImage, setSelectedImage] = useState<WorkType | undefined>()
  return (
    <Container isImageSelected={!!selectedImage}>
      <WorksTitleContainer>
        <WorksTitle onClick={() => navigate('/')}>● BEN_SHIRAKAWA</WorksTitle>
      </WorksTitleContainer>
      <WorksContainer>
        {getWorks().map((image) => (
          <ImageContainer key={image}>
            <StaticImageFluid
              image={image}
              objectFit="scale-down"
              onClick={setSelectedImage}
            />
          </ImageContainer>
        ))}
      </WorksContainer>
      {selectedImage && (
        <OriginalImageModal
          image={selectedImage}
          closeModal={() => setSelectedImage(undefined)}
        />
      )}
    </Container>
  )
}

const Layout = styled.div`
  position: relative;
  background-color: #eaeaea;
`
const Container = styled.div<{ isImageSelected: boolean }>`
  position: relative;
  height: 100%;
  ${({ isImageSelected }) => isImageSelected && 'overflow:hidden;'}
`

const WorksTitleContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;
`
const WorksTitle = styled(Title)`
  padding: 3vh;
  color: black;
  background-color: #eaeaea;
`
const WorksContainer = styled.div`
  font-size: var(--font-size-large);
  margin-top: calc(6vh + 1em);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: calc(100vw - 100%);
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
`
const ImageContainer = styled.div`
  scroll-snap-align: center;
  width: 100%;
  margin: 2vh;
  cursor: pointer;
  .gatsby-image-wrapper {
    transition: filter 0.2s linear;
    width: 100%;
    max-height: 85vh;
    img {
      max-height: 80vh;
    }
  }
  :hover {
    outline: none;
    .gatsby-image-wrapper {
      filter: drop-shadow(8px 8px 4px rgba(0, 0, 0, 0.5));
    }
  }
`
