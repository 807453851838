import * as React from 'react'
import Modal from 'react-modal'
import { flexColEnd, flexRowCenter } from 'src/styles'
import styled, { css } from 'styled-components'

Modal.setAppElement('body')

export type ModalProps<T = {}> = T & {
  closeModal: (e?: React.MouseEvent) => void
}
export type ModalOptions = {
  circleTop?: boolean
  fullscreen?: boolean
  verticalAlign?: 'center' | 'bottom'
}
type AsModalType = <T>(
  Component: React.FC<ModalProps<T>>,
  options?: ModalOptions
) => React.FC<ModalProps<T>>
export const asModal: AsModalType = (Component, options = {}) => {
  return (props) => {
    const [className, setClassName] = React.useState('')
    // const { closeModal } = props
    const closeModal = () => setClassName('onClose')
    return (
      <StyledModal
        isOpen
        onRequestClose={closeModal}
        portalClassName="react-modal-portal"
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldFocusAfterRender={false}
      >
        <ModalAreaOverlay onClick={closeModal} tabIndex={-1} {...options}>
          <ModalArea
            {...options}
            onClick={(e) => e && e.stopPropagation()}
            onAnimationEnd={(e) => {
              if (e.animationName !== modalFadeOutFinalAnimationName) return
              props.closeModal()
            }}
            className={className}
          >
            <Component {...props} closeModal={closeModal} />
          </ModalArea>
        </ModalAreaOverlay>
      </StyledModal>
    )
  }
}
const StyledModal = styled(Modal)`
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  :focus {
    outline: none;
  }
`
const ModalAreaOverlay = styled.div<ModalOptions>`
  ${({ verticalAlign = 'center' }) =>
    verticalAlign === 'center' ? flexRowCenter : flexColEnd}
  width: 100%;
  height: 100%;
  :focus {
    outline: none;
  }
`

const modalFadeInVerticalDuration = 0.2
const modalFadeInHorizontalDuration = 0.2
const fadeInAnimation = css`
  clip-path: inset(50% 50% round 12px);
  animation: modalFadeInVertical ${modalFadeInVerticalDuration}s ease-in-out
      0.5s forwards,
    modalFadeInHorizontal ${modalFadeInHorizontalDuration}s ease-in-out
      ${modalFadeInVerticalDuration + 0.5}s forwards;
  @keyframes modalFadeInVertical {
    0% {
      clip-path: inset(calc(50% - 1px) 50% round 12px);
    }
    100% {
      clip-path: inset(calc(50% - 1px) 0 round 12px);
    }
  }
  @keyframes modalFadeInHorizontal {
    100% {
      clip-path: inset(0 0 round 12px);
    }
  }
`

const modalFadeOutFinalAnimationName = 'modalFadeOutHorizontal'
const modalFadeOutVerticalDuration = 0.1
const modalFadeOutHorizontalDuration = 0.2
const fadeOutAnimation = css`
  animation: modalFadeOutVertical ${modalFadeOutVerticalDuration}s ease-in-out
      forwards,
    ${modalFadeOutFinalAnimationName}2 ${modalFadeOutHorizontalDuration}s
      ease-in-out ${modalFadeOutVerticalDuration}s forwards,
    ${modalFadeOutFinalAnimationName} ${modalFadeOutHorizontalDuration}s
      ease-in-out
      ${modalFadeOutVerticalDuration + modalFadeOutHorizontalDuration}s forwards;

  @keyframes modalFadeOutVertical {
    0% {
      clip-path: inset(0 0 round 12px);
    }
    100% {
      clip-path: inset(calc(50% - 1px) 0 round 12px);
    }
  }
  @keyframes ${modalFadeOutFinalAnimationName}2 {
    100% {
      clip-path: inset(calc(50% - 1px) calc(50% - 1px) round 12px);
    }
  }
  @keyframes ${modalFadeOutFinalAnimationName} {
    50% {
      clip-path: inset(25% calc(50% - 1px) round 12px);
    }
    100% {
      clip-path: inset(calc(50% - 1px) calc(50% - 1px) round 12px);
    }
  }
`
const ModalArea = styled.div<ModalOptions>`
  width: 100%;
  ${({ circleTop = false }) => circleTop && 'border-radius: 12px 12px 0 0;'}
  ${({ fullscreen = false }) =>
    fullscreen ? fullscreenStyle : 'max-width: 480px;'}
  background-color: rgba(0, 0, 0, 34%);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  ${fadeInAnimation}
  &.onClose {
    div {
      visibility: hidden;
    }
    ${fadeOutAnimation}
  }
`
const fullscreenStyle = css`
  /* height: 100%;
  display: flex; */
`
